export const partnerList = [
  {
    title: "Descartes MacroPoint",
    text: "Descartes MacroPoint is purpose-built to provide end-to-end visibility through every stage of the transportation journey, all from a single provider",
    image: "https://telegra.ph/file/098c6f06caf081516b47c.jpg",
  },
  {
    title: "Go Highway",
    text: "Helps brokers identifying carriers",
    image: "https://telegra.ph/file/c718278e9b59951dc727f.jpg",
  },
  {
    title: "FourKites",
    text: "Real-time transportation visibility allows you to have the exact location of your vehicles and shipments in real time",
    image: "https://telegra.ph/file/aa1e598ae51d1a8aa411c.png",
  },
  {
    title: "Project44",
    text: "Helps manage the supply chain with precision and efficiency by making vehicles visible at all times",
    image: "https://telegra.ph/file/9851b663b071b29a910b0.jpg",
  },
  {
    title: "TruckerTools",
    text: "Load tracking and digital freight matching solution that helps cut your costs",
    image: "https://telegra.ph/file/818eacc3e388c2fe23066.jpg",
  },
  {
    title: "Trucker Cloud",
    text: "Allows tech companies to quickly connect with the industry's leading ELD's, so you can gather data and gain insight, faster.",
    image: "https://telegra.ph/file/f761a922d9b79a974247d.jpg",
  },
];
