export const productsData = [
  {
    title: "ELD Compliance Solution",
    text: "Our FMCSA-certified ELD compliance solution gives you a wide range of easy-to-use features, making it simple to stay compliant and keep your operations running smoothly.",
    img: "product1",
  },
  {
    title: "Dispatch Board",
    text: "Leader ELD makes managing your loads faster and easier. Our smart system gives you real-time updates on possible delays based on where your vehicle is, and helps streamline your truck routing with instant alerts when drivers arrive at their pickup or finish a delivery.",
    img: "product2",
  },
  {
    title: "Dash Camera System",
    text: "With Leader ELD's Dash Camera System, you can lower the price of insurance and raise safety ratings for the whole fleet. According to the FMCSA, accidents can result in expenses up to $1.2 million. By protecting your fleet, you may avoid these costs and save millions of dollars.",
    img: "product6",
  },
  {
    title: "GPS Tracking",
    text: "Optimize your fleet management by utilizing real-time GPS for efficient dispatch and tracking. Thoroughly monitor all vehicle movements and employ our advanced tools to make strategic decisions, leveraging weather and traffic data to consistently surpass customer expectations.",
    img: "product4",
  },
  {
    title: "Chat on drivers and dispatchers",
    text: "We know how important it is to look at each job as a whole, and we believe that clear, simple communication works best.",
    img: "product5",
  },
];
