import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Custom404 from "src/pages/404";
import HomePage from "src/pages/home";
import PrivacyPolicy from "src/pages/privacy/Privacy";
import Returns from "src/pages/privacy/Returns";
import TermsOfService from "src/pages/privacy/TermsOfService";

function RoutElements() {
  useEffect(() => {
    AOS.init({
      easing: "ease-out-cubic",
      once: true,
      offset: 0,
      duration: 800,
    });
  }, []);

  return (
    <div className="root">
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="*" element={<Custom404 />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/returns" element={<Returns />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </div>
  );
}

export default RoutElements;
