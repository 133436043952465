import { ConfigProvider } from "antd";

interface ProviderProps {
  children: JSX.Element;
}

function AntConfigProvider({ children }: ProviderProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 14,
          fontFamily: "Poppins",
        },
        components: {
          Button: {
            controlHeight: 56,
            paddingContentHorizontal: 30,
          },
          Anchor: {
            colorPrimary: "#6800ff",
            fontSize: 15,
            fontFamily: "Poppins",
            colorText: "#495057",
          },
          Image: {},
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}

export default AntConfigProvider;
